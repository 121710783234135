<mat-card class="safe-zones">
  <ngrx-busy #szBusy>

    <div class="safe-zones-wrapper">
      <app-headline>
        <h1 app-headline-title
            color="primary">Safe zones</h1>
      </app-headline>
      <app-safe-zone-card *ngFor="let safeZone of safeZones$ | async"
                          [safeZone]="safeZone"
                          class="safe-zone"></app-safe-zone-card>
    </div>
  </ngrx-busy>
</mat-card>

<mat-card class="feeds">
  <ngrx-busy #feedBusy>
    <app-headline>
      <h1 app-headline-title
          color="primary">Feed</h1>
    </app-headline>

    <ng-container *ngIf="feeds$ | async as feeds">
      <ng-container *ngIf="customer$ | async as customer">
        <cdk-virtual-scroll-viewport itemSize="80">
          <app-feed-card *cdkVirtualFor="let feed of feeds"
                         [feed]="feed"
                         [tz]="customer.time_zone"
                         class="feed"></app-feed-card>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </ng-container>
  </ngrx-busy>
</mat-card>

<mat-card class="sessions">
  <ngrx-busy #sessionsBusy>
    <app-headline>
      <h1 app-headline-title
          color="primary">Sessions</h1>
    </app-headline>

    <div class="sessions-wrapper">
      <app-session-card *ngFor="let session of sessions$ | async"
                        [session]="session"
                        class="session"></app-session-card>
    </div>
  </ngrx-busy>
</mat-card>

<mat-card class="notification-settings">
  <app-headline>
    <h1 app-headline-title
        color="primary">Notification settings</h1>
  </app-headline>

  <ng-container *ngIf="notificationSettings$ | async as settings">
    <mat-tab-group mat-align-tabs="center"
                   class="notifications-tabs">
      <mat-tab label="Push">
        <div class="notification-target">
          <div *ngFor="let set of settings.push"
               class="notification-group">
            {{set.name}}
            <mat-slide-toggle
              *ngFor="let item of set.items"
              color="primary"
              labelPosition="before"
              [checked]="item.enabled"
              [disabled]="true">
              {{item.name}}
            </mat-slide-toggle>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Email">
        <div class="notification-target">
          <div *ngFor="let set of settings.email"
               class="notification-group">
            {{set.name}}
            <mat-slide-toggle
              *ngFor="let item of set.items"
              color="primary"
              labelPosition="before"
              [checked]="item.enabled"
              [disabled]="true">
              {{item.name}}
            </mat-slide-toggle>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</mat-card>

<ng-container *ngIf="customer$ | async as customer">
  <mat-card *ngIf="customer.id == 'dce0bbdd-0109-317d-b6b9-f045d32326a5'" class="system">
    <button mat-button
            (click)="shareSimba()">
      Share Simba
    </button>

    <button mat-button
            (click)="unshareSimba()">
      Unshare Simba
    </button>
  </mat-card>
</ng-container>
