import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  publishReplay,
  refCount, startWith,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import * as L from 'leaflet';
import 'Leaflet.MultiOptionsPolyline';
import {
  DeviceMode,
  GetTrack,
  CalculateWalkParts,
  RecalculateWalkCommand,
  SystemDialog,
  TracksClient,
  WarningConfirm,
  SpeedLevel,
  TrackPart,
  Track,
  GetStraightParts,
  StraightPart,
  DebugWalkParts,
  RelativeTrackPoint,
  ActivityState,
  CustomerPartial
} from 'shared';
import {PetDetailComponent} from '../pet-detail/pet-detail.component';
import {deviceModeShade, speedLevelShade, TrackColor} from '../utils';
import {AchievementsPublicClient, AchievementType, TimelinePublicClient, Walk, WalkPoint} from '../public-clients';

namespace LL {
  export function startMarker(latlng: L.LatLngExpression): L.CircleMarker {
    return L.circleMarker(latlng, {
      radius: 8,
      fillColor: '#BFBFBF',
      color: '#ffffff',
      opacity: 1,
      fillOpacity: 1,
      weight: 2
    });
  }

  export function finishMarker(latlng: L.LatLngExpression): L.Marker {
    return  L.marker(latlng, {
      icon: L.divIcon({
        html: `<i class="mat-icon material-icons" style="background: ${deviceModeShade(DeviceMode.Walk)}; border-radius: 50%; color: white; border: solid white 2px;">sports_score</i>`,
        iconSize: [24, 24],
        className: 'finish-icon'
      })
    });
  }

  export function multiOptionsPolyline(latlng: LatLngWithSpeed[]): L.MultiOptionsPolyline {
    return L.multiOptionsPolyline(latlng, {
      multiOptions: {
        optionIdxFn: (latLng) => {
          switch (latLng.speedLevel) {
            case SpeedLevel.OnFoot: return 0;
            case SpeedLevel.Car: return 1;
            case SpeedLevel.Plane: return 2;
            case SpeedLevel.Off: return 3;
            default: return -1;
          }
        },
        options: [
          { color: TrackColor.OnFoot },
          { color: TrackColor.Car },
          { color: TrackColor.Plane },
          { color: TrackColor.Off }
        ]
      },
      weight: 5
    });
  }

  export class LatLngWithSpeed extends L.LatLng {
    speedLevel: SpeedLevel;

    constructor(latitude: number, longitude: number, speedLevel: SpeedLevel, altitude?: number) {
      super(latitude, longitude, altitude);
      this.speedLevel = speedLevel;
    }
  }
}

export function createEmbeddedView<T>(ref: TemplateRef<T>, context: T): EmbeddedViewRef<T> {
  const view = ref.createEmbeddedView(context);
  view.detectChanges();
  return view;
}

@Component({
  selector: 'app-track-detail',
  templateUrl: './track-detail.component.html',
  styleUrls: ['./track-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackDetailComponent implements OnInit, OnDestroy {
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;
  @ViewChild('legend', {static: true}) legend: TemplateRef<any>;
  @ViewChild('trackColorsLegend', {static: true}) trackColorsLegend: TemplateRef<any>;
  @ViewChild('popup', {static: true}) popup: TemplateRef<any>;
  @ViewChild('relativePointPopup', {static: true}) relativePointPopup: TemplateRef<any>;
  @ViewChild('straightPartPopup', {static: true}) straightPartPopup: TemplateRef<any>;

  @ViewChild('toolbar') toolbar: TemplateRef<any>;
  @ViewChild('close', {static: true}) close: TemplateRef<any>;

  Number = Number;
  DeviceMode = DeviceMode;

  recalculateForm: UntypedFormGroup;
  ratioForm: UntypedFormGroup;

  track$: Observable<Track & Walk & { walkersD: (CustomerPartial & { duration: moment.Duration })[] }>;
  id$: Observable<{pet_id: string, id: string}>;
  // completed$: Observable<boolean>;
  battery$: Observable<any[]>;
  achievements$: Observable<AchievementType[]>;

  constructor(
    route: ActivatedRoute,
    private tracksClient: TracksClient,
    private achievementsClient: AchievementsPublicClient,
    private timelineClient: TimelinePublicClient,
    private fb: UntypedFormBuilder,
    private systemDialog: SystemDialog,
    private parent: PetDetailComponent,
    private cdr: ChangeDetectorRef
  ) {
    this.recalculateForm = this.fb.group({
      accuracy_percentile: [90],
      foot_kalman_decay: [3],
      car_kalman_decay: [15],
      bunch_area: [15],
      douglas_tolerance: ['7.0'],
      curve_level: [2],
      curve_strength: ['0.2'],
      ema_alpha: [0.133],
      ema_window: [15]
    });
    this.recalculateForm.disable();

    this.ratioForm = this.fb.group({
      max_duration: [90],
      min_steps: [100],
      min_windows_number: [4],
      window_points_number: [5],
      min_distance: [50],
      speed_dispersion: [0.35],
      max_two_points_distance: [20],
      max_two_points_duration: [20],
      max_accuracy: [20],
      boundary_activity_and_point_delta: [9]
    });
    this.ratioForm.disable();

    this.id$ = route.params.pipe(
      map(param => ({pet_id: route.parent.snapshot.params.id, id: param.id})),
      distinctUntilChanged()
    );

    this.track$ = this.id$.pipe(
      switchMap(({pet_id, id}) => tracksClient.getTrack(new GetTrack({track_id: id})).pipe(map(track => ({track, pet_id}))) ),
      switchMap(({track, pet_id}) => track.rejected_reason == null ? this.timelineClient.getWalk(pet_id, track.id).pipe(map(walk => ({walk, track}))) : of({walk: <Walk>{}, track})),
      withBusy(() => parent.busy),
      withBusy(() => this.busy),
      map(({walk, track}) =>
        ({
          ...track,
          ...walk,
          walkersD: walk.walkers ? walk.walkers.map(walker => ({
            ...walker,
            duration: track.connections ? track.connections.find(c => c.id === walker.id)?.duration : null
          })) : []
        } as Track & Walk & { walkersD: (CustomerPartial & { duration: moment.Duration })[] })),
      publishReplay(1),
      refCount()
    );
    // this.completed$ = this.track$.pipe(map(track => track.status === TrackStatus.Completed));
    this.battery$ = this.track$.pipe(map(track => {
      const points = (track.parts.reduce((a, b) => a.concat(b.points), [] as WalkPoint[]) ?? []);
      return [
        {
          name: 'Charge',
          series: points.map(point => ({
            ...point,
            name: point.timestamp.toDate(),
            value: point.battery_charge,
          }))
        }
      ];
    }));

    this.achievements$ = this.achievementsClient.getAchievementTypes().pipe(
      switchMap(types => this.track$.pipe(
        map(track => track.achievements.map(ach => types.find(t => t.id == ach.id)))
      ))
    );
  }

  dateTickFormatting(val: any): string {
    if (!(val instanceof Date)) { return; }
    return new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'utc'
    }).format(val);
  }

  ngOnInit(): void {
    this.parent.toolbar.push(this.close);

    this.parent.registerMode('filtered', 'Processed walk', this.filtered.bind(this), true);
    this.parent.registerMode('raw', 'Raw track', this.raw.bind(this));
    this.parent.registerMode('compare', 'Recalculate', this.compare.bind(this));
    if (this.isDebugActive()) {
      this.parent.registerMode('ratio', 'View track ratios', this.renderRatio.bind(this));
      this.parent.registerMode('debug', 'Debug', this.debug.bind(this));
    }
  }

  ngOnDestroy(): void {
    // this.parent.unregisterMode('both');
    this.parent.unregisterMode('filtered');
    this.parent.unregisterMode('raw');
    this.parent.unregisterMode('compare');
    this.parent.unregisterMode('ratio');
    this.parent.unregisterMode('debug');
    this.parent.toolbar.remove(this.close);
  }

  private isDebugActive(): boolean {
    return localStorage.getItem('debug') === 'true' ;
  }

  private raw(onDeactivate: Observable<any>): void {
    this.parent.toolbar.push(this.toolbar);
    const legend = this.createRawLegend();
    let layer: L.LayerGroup | null = null;

    const clear = () => {
      if (layer) { layer.remove(); }
    };

    this.track$.pipe(
      takeUntil(onDeactivate)
    ).subscribe(track => {
      clear();
      if (!track.points || !track.points.length) { return; }

      const polyline = L.polyline(track.points.map(point => [point.lat, point.lng]), {
        color: '#ff0000',
        weight: 1
      });

      const markers = track.points.map((point, ix) => L.circleMarker([point.lat, point.lng], {
        radius: 6,
        fillColor: deviceModeShade(point.mode, track.points.length - 1 - ix, track.points.length),
        color: '#ffffff',
        opacity: 1,
        fillOpacity: 1,
        weight: 0.5
      })
      .bindTooltip(point.timestamp.utc().format('YYYY-MM-DD HH:mm:ss'))
      .bindPopup(createEmbeddedView(this.popup, {point}).rootNodes[0]));

      layer = L.layerGroup([polyline, ...markers]).addTo(this.parent.map);

      this.parent.map.fitBounds(polyline.getBounds());
    }, () => {}, () => {
      clear();
      legend.remove();
      this.parent.toolbar.remove(this.toolbar);
    });
  }

  private createRawLegend(): L.Control {
    const legend = new L.Control({ position: 'topright' });
    legend.onAdd = () => createEmbeddedView(this.legend, {modes: [
        { mode: DeviceMode.Walk, title: 'Walk', color: deviceModeShade(DeviceMode.Walk) },
        { mode: DeviceMode.Alarm, title: 'Alarm', color: deviceModeShade(DeviceMode.Alarm) },
        { mode: DeviceMode.Lost, title: 'Lost', color: deviceModeShade(DeviceMode.Lost) },
      ]}).rootNodes[0];
    legend.addTo(this.parent.map);
    return legend;
  }

  private filtered(onDeactivate: Observable<any>): void {
    let layer: L.LayerGroup | null = null;

    const legend = this.createTrackColorsLegend();

    const clear = () => {
      if (layer) { layer.remove(); }
    };

    this.track$.pipe(takeUntil(onDeactivate)).subscribe(track => {
      clear();
      const points = (track.parts ? track.parts.reduce((a, b) => a.concat(b.points), [] as WalkPoint[]) ?? [] : []);
      if (points.length < 2) { return; }

      const polyline = LL.multiOptionsPolyline(track.parts
        .map(part => part.points.map(point => new LL.LatLngWithSpeed(point.lat, point.lng, part.level)))
        .reduce((a, b) => a.concat(b), []));

      const start = points[0];
      const startMarker = LL.startMarker([start.lat, start.lng])
        .bindTooltip(start.timestamp.utc().format('YYYY-MM-DD HH:mm:ss'));

      const finish = points[points.length - 1];
      const finishMarker = LL.finishMarker([finish.lat, finish.lng])
        .bindTooltip(finish.timestamp.utc().format('YYYY-MM-DD HH:mm:ss'));

      layer = L.layerGroup([polyline, startMarker, finishMarker]).addTo(this.parent.map);

      this.parent.map.fitBounds(polyline.getBounds());
    }, () => {}, () => {
      clear();
      legend.remove();
    });
  }

  private createTrackColorsLegend(): L.Control {
    const legend = new L.Control({ position: 'topright' });
    legend.onAdd = () => createEmbeddedView(this.trackColorsLegend, { trackColorsInfo: [
        { title: 'On Foot', color: TrackColor.OnFoot },
        { title: 'Car', color: TrackColor.Car },
        { title: 'Plane', color: TrackColor.Plane },
        { title: 'Off', color: TrackColor.Off },
      ]}).rootNodes[0];
    legend.addTo(this.parent.map);
    return legend;
  }

  private compare(onDeactivate: Observable<any>): void {
    this.recalculateForm.enable();
    this.cdr.detectChanges();

    onDeactivate.subscribe(() => {
      this.recalculateForm.disable();
      this.cdr.detectChanges();
    });

    const legend = this.createTrackColorsLegend();

    this.renderRaw(onDeactivate);

    let recalculatePolyline: L.MultiOptionsPolyline | null = null;

    const clear = () => {
      if (recalculatePolyline) { recalculatePolyline.remove(); }
    };

    this.track$.pipe(
      switchMap(track => this.recalculateForm.valueChanges.pipe(
        debounceTime(400),
        startWith(this.recalculateForm.value),
        map(form => ({...form, id: track.id})))
      ),
      switchMap(form => this.id$.pipe(map(ids => ({...form, pet_id: ids.pet_id})))),
      switchMap(params => this.tracksClient.calculateWalkParts(new CalculateWalkParts(params)).pipe(
        catchError((err) => {
          this.recalculateForm.setErrors(err);
          return new Observable<TrackPart[]>();
        })
      )),
      takeUntil(onDeactivate)
    ).subscribe(parts => {
      clear();
      if (!parts.length) { return; }

      recalculatePolyline = LL.multiOptionsPolyline(parts
        .map(part => part.points.map(point => new LL.LatLngWithSpeed(point.lat, point.lng, part.level)))
        .reduce((a, b) => a.concat(b), []))
        .addTo(this.parent.map);
    }, () => {}, () => {
      clear();
      legend.remove();
    });
  }

  private renderRatio(onDeactivate: Observable<any>): void {
    this.ratioForm.enable();
    this.cdr.detectChanges();

    onDeactivate.subscribe(() => {
      this.ratioForm.disable();
      this.cdr.detectChanges();
    });

    const layers: L.LayerGroup[] | null = [];

    const clear = () => {
      if (layers) {
        layers.forEach(layer => layer.remove());
      }
    };

    this.id$.pipe(
      switchMap(track => this.ratioForm.valueChanges.pipe(
        debounceTime(400),
        startWith(this.ratioForm.value),
        map(form => ({...form, track_id: track.id})))
      ),
      switchMap(params => this.tracksClient.getStraightParts(new GetStraightParts(params)).pipe(
        withBusy(() => this.parent.busy),
        withBusy(() => this.busy),
        catchError((err) => {
          this.recalculateForm.setErrors(err);
          return new Observable<StraightPart[]>();
        })
      )),
      takeUntil(onDeactivate)
    ).subscribe({
        next: (parts) => {
          clear();
          if (!parts || !parts.length) {
            return;
          }

          parts.forEach(part => {
            const polyline = L.polyline(part.points
              .map(point => [point.location.lat, point.location.lng]), {
              color: '#000000',
              weight: 3
            });

            polyline.bindPopup(createEmbeddedView(this.straightPartPopup, {part}).rootNodes[0]).addTo(this.parent.map);

            layers.push(L.layerGroup([polyline]).addTo(this.parent.map));

          });
        },
        error: () => {},
        complete: () => { clear(); }
      }
    );

    this.renderRaw(onDeactivate);
  }

  renderRaw(onDeactivate: Observable<any>): void {
    let rawPolyline: L.Polyline | null = null;
    const clearRaw = () => {
      if (rawPolyline) { rawPolyline.remove(); }
    };

    this.track$.pipe(
      takeUntil(onDeactivate)
    ).subscribe(track => {
      clearRaw();
      if (!track.points || !track.points.length) { return; }
      rawPolyline = L.polyline(track.points.map(point => [point.lat, point.lng]), {
        color: '#ff0000',
        weight: 1
      }).addTo(this.parent.map);

      this.parent.map.fitBounds(rawPolyline.getBounds());
    }, () => {}, () => clearRaw());
  }

  calcDurationPercent(track: Walk, time: moment.Duration): number {
    if (time == null) { return 0; }
    const walkTime = track.duration.asSeconds();
    const percent = (time.asSeconds() * 100) / walkTime;
    return Math.round(percent);
  }

  private debug(onDeactivate: Observable<any>): void {

    let layer: L.LayerGroup | null = null;

    const legend = this.createTrackColorsLegend();

    this.renderRaw(onDeactivate);

    const clear = () => {
      if (layer) { layer.remove(); }
    };

    this.track$.pipe(
      switchMap(track => this.recalculateForm.valueChanges.pipe(
        debounceTime(400),
        startWith(this.recalculateForm.value),
        map(form => ({...form, id: track.id})))
      ),
      switchMap(form => this.id$.pipe(map(ids => ({...form, pet_id: ids.pet_id})))),
      switchMap(params => this.tracksClient.debugWalkParts(new DebugWalkParts(params)).pipe(
        catchError((err) => {
          this.recalculateForm.setErrors(err);
          return new Observable<TrackPart[]>();
        })
      )),
      takeUntil(onDeactivate)
    ).subscribe({
      next: (relativePoints: RelativeTrackPoint[]) => {
        clear();
        if (!relativePoints.length) { return; }

        const markers = relativePoints.map((point, ix) => {
          return L.circleMarker([point.location.lat, point.location.lng], {
            radius: 6,
            fillColor: speedLevelShade(point.speed_level, relativePoints.length - 1 - ix, relativePoints.length),
            color: '#ffffff',
            opacity: 1,
            fillOpacity: 1,
            weight: 0.5
          })
            .bindTooltip(point.timestamp.utc().format('YYYY-MM-DD HH:mm:ss'))
            .bindPopup(createEmbeddedView(this.relativePointPopup, {point}).rootNodes[0]);
        });

        layer = L.layerGroup([...markers]).addTo(this.parent.map);

      }, complete: () => {
        clear();
        legend.remove();
      }});
  }

  async recalculateConfirm(): Promise<void> {
    const isOk = await this.systemDialog.confirm(`Are you sure you want to recalculate this track?`, WarningConfirm);
    if (!isOk) { return; }
    const track = await this.track$.pipe(take(1)).toPromise();
    await this.tracksClient.recalculateWalk(new RecalculateWalkCommand({...this.recalculateForm.value, id: track.id}))
      .pipe(withBusy(() => this.busy)).toPromise();
  }
}
